import { APP_INITIALIZER, ApplicationModule, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { RouterModule } from '@angular/router';
import { ConsentBottomSheetModule } from '@core/ui-components/consent-bottom-sheet/consent-bottom-sheet.module';
import { AuthModule } from './auth/auth.module';
import { LoginService } from '@core/auth/login.service';
import { TrustlaneLoginService, provideAppConf } from './auth/trustlane-login.service';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { httpInterceptorsProviders } from './auth/interceptors/interceptor.index';
import { Platform } from '@angular/cdk/platform';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, TEDateFormat } from '@core/ui-components/period-select/period-select.module';
import { EnvironmentService } from '@core/domains/environment/environment.service';
import { Environment } from '@core/domains/environment/environment';
import { AdminMeStoreService } from '@core/domains/adminMe/adminMe-store.service';
import { AdminMeService } from '@core/domains/adminMe/adminMe.service';
import { MatomoConsentMode, MatomoInitializationMode, MatomoModule, MatomoRouterModule } from 'ngx-matomo-client';
import { MatDialogModule } from '@angular/material/dialog';

function preloadEnvironment(env: EnvironmentService) {
  return (): Promise<Environment> => {
    return env.initEnv();
  }
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    ApplicationModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatomoRouterModule,
    RouterModule,
    AuthModule,
    MatomoModule.forRoot({
        mode: MatomoInitializationMode.AUTO_DEFERRED,
        disabled: window.location.host.includes('localhost'),
        requireConsent: MatomoConsentMode.TRACKING,
        // acceptDoNotTrack: true
    }),
    ConsentBottomSheetModule,
    MatSnackBarModule,
    TranslocoRootModule,
    MatDialogModule
  ],
  providers: [
    EnvironmentService,
    { provide: LoginService, useClass: TrustlaneLoginService },
    httpInterceptorsProviders,
    {
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: preloadEnvironment,
        deps: [EnvironmentService]
    },
    AdminMeStoreService,
    provideAppConf,
    AdminMeService,
    {
        provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
        useValue: { duration: 4000, horizontalPosition: 'end', verticalPosition: 'top' }
    },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
    { provide: MAT_DATE_FORMATS, useValue: TEDateFormat },
    // preLoadTranslationsFiles,
    Title,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }
