import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivationComponent } from './activation/activation.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordConfirmComponent } from './reset-password/confirm/reset-password-confirm.component';
import { ResetPasswordEmailComponent } from './reset-password/email/reset-password-email.component';
import { ResetPasswordNewComponent } from './reset-password/new/reset-password-new.component';
import { SignUpComponent } from './signup/signup.component';
import { ValidationComponent } from './validation/validation.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { authRoutes } from './auth.routes';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslocoModule } from '@jsverse/transloco';
import { ActivationService } from './activation/activation.service';
import { ResetPasswordEmailService } from './reset-password/email/reset-password-email.service';
import { ResetPasswordNewService } from './reset-password/new/reset-password-new.service';
import { SignUpService } from './signup/signup.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorManagementService } from '@core/tools/error.codes';



@NgModule({
  declarations: [
    LoginComponent,
    SignUpComponent,
    ResetPasswordEmailComponent,
    ResetPasswordConfirmComponent,
    ResetPasswordNewComponent,
    ValidationComponent,
    ActivationComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(authRoutes),
    ReactiveFormsModule,
    TranslocoModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatSnackBarModule
  ],
  providers: [
    ActivationService,
    SignUpService,
    ResetPasswordEmailService,
    ResetPasswordNewService,
    ErrorManagementService
  ]
})
export class AuthModule { }
