import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NavigationEnd, Router } from '@angular/router';
import { EnvironmentService } from '@core/domains/environment/environment.service';
import { ConsentBottomSheetComponent } from '@core/ui-components/consent-bottom-sheet/consent-bottom-sheet.component';
import { TranslocoService } from '@jsverse/transloco';
import { filter, take, tap } from 'rxjs/operators';
import { greetings } from './greetings';
import { MatomoInitializerService, MatomoTracker } from 'ngx-matomo-client/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private bottomSheet: MatBottomSheet,
    private loco: TranslocoService,
    private matomoInit: MatomoInitializerService,
    private envService: EnvironmentService,
    private tracker: MatomoTracker,
    private router: Router
  ) { }

  ngOnInit(): void {
    greetings();
    this.router.events.pipe(
      filter((e: unknown): e is NavigationEnd => e instanceof NavigationEnd),
      filter((e: NavigationEnd) => !e.url.includes('auth')), // execute following pipeline if not auth urls
      take(1), // execute once
      tap(() => this.initMatomoConfig()),
      tap(() => this.initTracker())
    ).subscribe();
  }

  initMatomoConfig(): void {
    const env = this.envService.environment.matomo;
    this.matomoInit.initializeTracker({
      siteId: env.site_id,
      trackerUrl: env.tracker_url
    });
  }

  initTracker(): void {
    this.tracker.hasRememberedConsent().then(
      (result: boolean) => this._onPromiseFulfilled(result),
      (_: unknown) => this._onPromiseRejected(_)
    )
  }

  openConsent(): void {
    const consent = this.bottomSheet.open(ConsentBottomSheetComponent, {
      hasBackdrop: false,
      panelClass: 'consent-panel',
      data: {
        message: this.loco.translate('TRACKING.consent')
      }
    });
    consent.afterDismissed().subscribe({next: (result: boolean) => result ? this.optIn() : this.optOut()})
  }



  optIn(): void {
    this.tracker.rememberConsentGiven(24*30);
  }

  optOut(): void {
    this.tracker.optUserOut();
    sessionStorage.setItem('optOut', 'true');
  }

  private _onPromiseFulfilled(result: boolean): void {
    const isOptOut = sessionStorage.getItem('optOut') === 'true';
    if (!result && !isOptOut) {
      this.openConsent();
    }
  }

  private _onPromiseRejected(_: unknown): void {
    console.warn(_);
  }
}
