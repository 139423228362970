import { LangDefinition, Translation } from '@jsverse/transloco';

export const AVAILABLE_LANGUAGES = ['fr', 'en'] as const;
export const AVALAIBLE_LANGUAGES_DEFINITIONS: LangDefinition[] = AVAILABLE_LANGUAGES.reduce((acc, curr) => [...acc, {id: curr, label: curr}], [] as LangDefinition[]);

export type Translations = {
  [_ in typeof AVAILABLE_LANGUAGES[number]]: Translation;
}


