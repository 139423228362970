export const greetings = (): void => console.log(`
       __          __  _
       \\ \\        / / | |
        \\ \\  /\\  / /__| | ___ ___  _ __ ___   ___
         \\ \\/  \\/ / _ \\ |/ __/ _ \\| '_ \` _ \\ / _ \\
          \\  /\\  /  __/ | (_| (_) | | | | | |  __/
           \\/| \\/ \\___|_|\\___\\___/|_| |_| |_|\\___|
             | |_ ___
  ____  _  __| __/ _ \\     _____              _____
 / __ \\| |/ _| || (_) |   / ____|            / ____|
| |  | | | |_ \\__\\___/   | (___   __ _  __ _| (___
| |  | | |  _/ _ \\/ _ \\   \\___ \\ / _\` |/ _\` |\\___ \\
| |__| | | ||  __/ (_) |  ____) | (_| | (_| |____) |
 \\____/|_|_| \\___|\\___/  |_____/ \\__,_|\\__,_|_____/


`)
