import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@core/domains/environment/environment.service';


export interface AdminPayload {
  email: string;
  first_name: string;
  last_name: string;
}

export interface NewAdmin {
  id: string;
  first_name: string;
  last_name: string;
}

export interface ActivationPayload {
  uid: string | null;
  token: string | null;
}


@Injectable()
export class SignUpService {
  baseUrl: string;

  constructor(
    private envService: EnvironmentService,
    private http: HttpClient,
  ) {
    const domain = this.envService.environment.api_domain;
    this.baseUrl = `${domain}/api/auth`;
  }

  onLicenceLogin(login: string, password: string):  Observable<void> {
    const httpOptions = {
      headers: this.getHeader(login, password)
    };
    return this.http.get<void>(`${this.baseUrl}/admins/v2/check_license/`, httpOptions);
  }

  onAdminCreation(payload: AdminPayload, login: string, password: string): Observable<NewAdmin> {
    const httpOptions = {
      headers: this.getHeader(login, password)
    };
    return this.http.post<NewAdmin>(`${this.baseUrl}/admins/v2/`, payload, httpOptions);
  }

  resendActivation(email: string, login: string, password: string): Observable<string> {
    const httpOptions = {
      headers: this.getHeader(login, password)
    };
    return this.http.post<string>(`${this.baseUrl}/admins/v2/resend_activation/`, {email}, httpOptions);
  }

  activation(payload: ActivationPayload): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/admins/v2/activation/`, payload);
  }

  getHeader(login: string, pwd: string): HttpHeaders {
    const token = btoa(`${login}:${pwd}`);
    return new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Authorization': `Basic ${token}`
    });
  }
}
