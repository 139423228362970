import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { getBrowserLang } from '@jsverse/transloco';

@Component({
  selector: 'olfeo-consent-bottom-sheet',
  templateUrl: './consent-bottom-sheet.component.html',
  styleUrls: ['./consent-bottom-sheet.component.scss']
})
export class ConsentBottomSheetComponent {
  browserLang = getBrowserLang();
  
  constructor(
    private bottomSheetRef: MatBottomSheetRef<ConsentBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {message: string}
  ) { }

  dismiss(result: boolean): void {
    this.bottomSheetRef.dismiss(result);
  }
}
