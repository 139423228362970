<ng-container *transloco="let t; read: 'reset.CONFIRM'">
  <div class="full-height">
    <div class="cube"></div>
    <div class="cube2"></div>
    <div class="logo"></div>
    <section class="reset-password">
      <header class="title-container">
        <h4>{{ t('title') }}</h4>
      </header>
      <article>
        <div>
          <h4>{{ t('paragraph_1') }}</h4>
          <p>{{ t('paragraph_2') }}</p>
          <p>{{ t('paragraph_3') }}</p>
        </div>
      </article>
    </section>
  </div>
</ng-container>
