import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AdminMeService } from '@core/domains/adminMe/adminMe.service';

@Component({
  selector: 'olfeo-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['../auth.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: {scope: 'auth/validation', alias: 'validation'} }]
})
export class ValidationComponent implements OnInit {
  private _url: URL;
  loader = true;
  errors = false;

  constructor(
    private adminService: AdminMeService,
    private router: Router
  ) {
    this._url = new URL(window.location.href);
  }

  ngOnInit(): void {
    const uid = this._url.searchParams.get('uid');
    const token = this._url.searchParams.get('token');
    const new_email = this._url.searchParams.get('new_email');
    this.adminService.verifyEmail({uid, token, new_email}).pipe(
      tap(() => {
        this.errors = false;
        setTimeout(() => this.router.navigate(['./profile']), 5000);
      }),
      catchError(() => this.setError()),
      finalize(() => this.loader = false)
    ).subscribe();
  }

  setError(): Observable<boolean> {
    return of(this.errors = true);
  }
}
