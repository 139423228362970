<ng-container *transloco="let t; read: 'validation'">
  <div class="full-height">
    <div class="cube"></div>
    <div class="cube2"></div>
    <div class="logo"></div>
    <section class="reset-password">
      <header>
        <h4>{{ t('title') }}</h4>
      </header>
      <article>
        <div>
          <mat-progress-spinner *ngIf="loader"></mat-progress-spinner>
          <p *ngIf="!loader && !errors">
            <span>{{ t('success.paragraph_1') }}</span><br>
            <span>{{ t('success.paragraph_2') }}</span>
          </p>
          <p *ngIf="!loader && errors">
            <span>{{ t('failure.paragraph_1') }}</span><br>
            <span>{{ t('failure.paragraph_2') }}</span>
          </p>
        </div>
        <footer>
          <span></span>
          <a mat-flat-button color="primary" [routerLink]="['/profile']">{{ t('back') }}</a>
        </footer>
      </article>
    </section>
  </div>
</ng-container>
