import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@core/domains/environment/environment.service';
import { Observable } from 'rxjs/internal/Observable';

export interface ActivationPayload {
  uid: string;
  token: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class ActivationService {
  baseUrl: string;

  constructor(
    private env: EnvironmentService,
    private httpClient: HttpClient
  ) {
    this.baseUrl = `${this.env.environment.api_domain}/api/auth/admins/v2`;
  }

  activation(payload: ActivationPayload): Observable<unknown> {
    return this.httpClient.post(`${this.baseUrl}/activation/`, payload);
  }
}
