import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { QueryMap } from './query-options.service';

export interface Page {
  /**
   * Will set the offset if user request next page.
   */
  next: string | null;
  /**
   * Will set the offset if user request previous page.
   */
  previous: string | null;
  /**
   * Will define page's size.
   */
  limit: string | number;
  /**
   * The total number of items.
   */
  count: string | number;
}


export interface PageRequest extends QueryMap {
  offset: number;
  limit: number;
  search?: string;
  ordering?: string;
}

export type Direction = keyof Pick<Page, 'previous' | 'next'>;

export class Pagination {
  private _pageSizeOptions: number[] = [10, 25, 50, 100];
  offset: number;
  next: number | null;
  previous: number | null;
  limit: number;
  count: number;
  index: number;

  constructor (json: Page) {
    this.fromJson(json);
  }

  static parseUrl(url: string, param: string): number {
    const urlObj = new URL(url);
    return Number(urlObj.searchParams.get(param));
  }

  fromJson (json: Page): void {
    this.next = json.next ? Pagination.parseUrl(json.next, 'offset') : null;
    this.previous = json.previous ? Pagination.parseUrl(json.previous, 'offset') : null;
    this.count = json.count ? Number(json.count) : 0;
    this.limit = json.limit ? Number(json.limit) : 10;
    this.offset = this.getCurrentOffset();
    this.index = this.getCurrentIndex();
  }

  getPageOptions(direction: Direction): PageRequest {
    return {
      offset: Number(this[direction]),
      limit: this.limit,
    };
  }

  get pageSizeOptions(): number[] {
    return this._pageSizeOptions;
  }

  set pagesizeOptions(options: number[]) {
    this._pageSizeOptions = options;
  }

  /**
   * Method that returns the settings for querying the current page. Useful when user is deleting an item of a paginated list
   * If the user delete the last item of the current page, then the previous page will be reloaded if this method is used.
   * @returns the PageRequest object needed to querying the current page.
   */
  currentPage(): PageRequest {
    return {
      offset: this.count === this.limit + 1 ? 0 : this.offset,
      limit: this.limit
    };
  }

  /**
   * Returns the current offset, based on previous & next offsets.
   * @returns {number} return the current offset
   */
  getCurrentOffset(): number {
    const offset = this.next === null && this.previous !== null ? Number(this.previous) + Number(this.limit) :
                                        this.previous === null  ? 0 : Number(this.next) - Number(this.limit);
    return offset;
  }

  /**
   * Returns the current page index, based on current offset and page size.
   * @returns {number} returns the curent page index
   */
  getCurrentIndex(): number {
    return Number(this.offset) / Number(this.limit);
  }
}

@Injectable()
export class PaginationStoreService {
  private _currentPage: BehaviorSubject<Pagination | null> = new BehaviorSubject<Pagination | null>(null);
  public currentPage: Observable<Pagination | null> = this._currentPage.asObservable();

  set (page: Pagination): void {
    this._currentPage.next(page);
  }
}
