<ng-container>
  <div class="column">
    <div class="row">
      <span [innerHTML]="data.message"></span>
    </div>
    <div class="row actions">
      <button mat-stroked-button color="accent" (click)="dismiss(false)">{{ browserLang === 'fr' ? 'Refuser' : 'Decline' }}</button>
      <button mat-flat-button color="accent" (click)="dismiss(true)">{{ browserLang === 'fr' ? 'Accepter' : 'Approve' }}</button>
    </div>
  </div>
</ng-container>
