import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/tools/window';
import { Environment } from './environment';
import { EnvironmentIO } from './environment.interfaces';

const HEADER = {
  method: 'GET',
  headers: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Accept': 'application/json'
  }
};

@Injectable()
export class EnvironmentService {
  private _environment: Environment;

  constructor(
    @Inject(WINDOW) private window: Window
  ) {}

  get environment(): Environment {
    return this._environment;
  }

  set environment(env: Environment) {
    this._environment = env;
  }

  private _isDevMode(): boolean {
    const host = this.window.location.host;
    return ['127.0.0.1', 'localhost', 'dev', 'oc-', 'release-'].some((h: string) => host.startsWith(h));
  }

  read(): Promise<Response> {
    return fetch('./assets/environment/environment.json', HEADER);
  }

  async initEnv(): Promise<Environment> {
    const result: Response = await this.read();
    const fallback = result.status === 404 ? (this._isDevMode() ? await this._fetchLocalHostConfig() : await this._fetchFallbackConfig()) : result;
    const json: EnvironmentIO = (result.status === 200 ? await result.json() : await fallback.json()) as EnvironmentIO;

    this.environment = new Environment(json);
    return this.environment;
  }

  private _fetchFallbackConfig(): Promise<Response> {
    return fetch('./assets/environment/fallback.json', HEADER);
  }

  private _fetchLocalHostConfig(): Promise<Response> {
    return fetch('./assets/environment/localhost.json', HEADER);
  }
}
