<ng-container>
  <div class="period-selector-container"
      [attr.aria-labelledby]="formField?.getLabelId()"
      (focusin)="onFocusIn($event)"
      (focusout)="onFocusOut($event)">
    <div *ngIf="mode === 'daterange'">
      <mat-date-range-input [rangePicker]="picker" [dateFilter]="dateFilter">
        <input matStartDate [value]="rangePickerValue.start_date" (dateChange)="rangeSelect($event)" [disabled]="true">
        <input matEndDate [value]="rangePickerValue.end_date" (dateChange)="rangeSelect($event)" [disabled]="true">
      </mat-date-range-input>
      <div class="toggle-container">
        <mat-icon class="toggle" color="primary" (click)="picker.open()">today</mat-icon>
      </div>
      <mat-date-range-picker #picker [disabled]="false"></mat-date-range-picker>
    </div>
    <button mat-icon-button *ngIf="mode === 'daterange' && rangeType === 0" (click)="closeRangePicker()" class="close-range-picker">
      <mat-icon>close</mat-icon>
    </button>

    <div *ngIf="mode === 'period'">
      <mat-select [(value)]="rangeType" (selectionChange)="select($event)" color="primary">
        <mat-option *ngFor="let opt of options" [value]="opt.value">{{ opt.label }}</mat-option>
        <mat-option *ngIf="withCustomOption" [value]="0">{{ browserLang === 'fr' ? 'Personnalisé' : 'Custom' }}</mat-option>
      </mat-select>
    </div>
  </div>
</ng-container>
