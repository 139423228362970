import { Serializer } from '@core/resources/serializer';
import { AdminMe, BaseAdminMe } from './admin-me';
import { ADMIN_CONFIG, AdminToken } from '../administrator/administrator';
import { Inject } from '@angular/core';
import { CAMPUS, SAAS_DNS_FILTER, SAAS_URL_FILTER } from '@core/constants/products.constant';

export class AdminMeSerializer implements Serializer<AdminMe> {
  constructor(
    @Inject(ADMIN_CONFIG) private config: AdminToken){}

  fromJson(json: BaseAdminMe): AdminMe {
    return this._setDefaultHomePageByApp(new AdminMe(json));
  }

  toJson(adminMe: AdminMe): Partial<BaseAdminMe> {
    return {
      email: adminMe.email,
      password: adminMe.password,
      first_name: adminMe.first_name,
      last_name: adminMe.last_name,
      role: adminMe.role
    };
  }

  private _setDefaultHomePageByApp(adminMe: AdminMe): AdminMe {
    const products = adminMe.products;
    if(this.config.licence === CAMPUS){
      adminMe.defaultModulePath = products.includes(CAMPUS) ? 'awareness' : 'support';
    }else {
      adminMe.defaultModulePath = products.includes(SAAS_URL_FILTER) || products.includes(SAAS_DNS_FILTER) ? 'trust-center/overview' : 'support'
    }
    return adminMe;
  }
}
