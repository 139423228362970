<ng-container *transloco="let t; read: 'reset.NEW'">
  <div class="full-height">
    <div class="cube"></div>
    <div class="cube2"></div>
    <div class="logo"></div>
    <section class="reset-password">
      <ng-template *ngIf="isActivated then activated; else notActivated"></ng-template>
      <ng-template #notActivated>
        <header class="title-container">
          <h4 class="secondary">{{ t('title') }}</h4>
        </header>
        <article>
          <p>{{ t('hints.first') }}</p>
          <p>{{ t('hints.second') }}</p>
          <form [formGroup]="passwordForm" (submit)="onSubmit()" fxFlex="60" style="display:contents;">
            <mat-form-field>
              <mat-label>{{ t('new') }}</mat-label>
              <input matInput type="password" name="password" formControlName="password"
                    placeholder="{{ t('new') }}"
                    required>
              <mat-error>{{ getErrorMessage('password') }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ t('confirm') }}</mat-label>
              <input matInput type="password" name="confirm" formControlName="confirm"
                    placeholder="{{ t('confirm') }}"
                    [errorStateMatcher]="matcher"
                    required>
              <mat-error>{{ getErrorMessage('confirm') }}</mat-error>
            </mat-form-field>
            <span *ngIf="apiErrors">
              <mat-error *ngFor="let msg of apiErrors">{{ msg }}</mat-error>
            </span>
            <footer>
              <span></span>
              <button mat-flat-button color="accent" class="withSpinner" type="submit" [disabled]="passwordForm.invalid">
                <span *ngIf="!loading">{{ t('submit') }}</span>
                <mat-spinner *ngIf="loading" diameter="25"></mat-spinner>
              </button>
            </footer>
          </form>
        </article>
      </ng-template>

      <ng-template #activated>
        <header class="title-container">
          <h4 class="secondary">{{ t('activate.subtitle') }}</h4>
        </header>
        <article class="activation">
          <div class="tile-content">
            <p *ngIf="isActivated && !apiErrors">
              <span>{{ t('activate.success.paragraph_2') }}</span><br>
              <span>{{ t('activate.success.paragraph_3') }}</span>
            </p>
            <p *ngIf="apiErrors">
              <span>{{ t('activate.failure.paragraph_1') }}</span><br>
              <span>{{ t('activate.failure.paragraph_2') }}</span><br><br>
              <mat-error *ngFor="let msg of apiErrors">{{msg}}</mat-error>
            </p>
          </div>
          <footer *ngIf="isActivated && !apiErrors">
            <span></span>
            <a mat-flat-button color="accent" [routerLink]="['/auth/login']">{{ t('login') }}</a>
          </footer>
        </article>
      </ng-template>
    </section>
  </div>
</ng-container>
