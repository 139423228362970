import { Component } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {UntypedFormGroup} from '@angular/forms';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'olfeo-forgot-password-email',
  templateUrl: './reset-password-confirm.component.html',
  styleUrls: ['../../auth.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: {scope: 'auth/reset', alias: 'reset'} }]
})
export class ResetPasswordConfirmComponent {
  handset: boolean;
  registerForm: UntypedFormGroup;

  constructor(
    public breakpoint: BreakpointObserver
  ) {
    this.breakpoint.observe(['(max-width: 812px)']).pipe(
      tap((state: BreakpointState) => this.handset = state.matches)
    ).subscribe();
  }
}
