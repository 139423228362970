import { isDevMode } from "@angular/core";

export const SAAS_URL_FILTER = 'saas_url_filter';
export const SAAS_DNS_FILTER = 'saas_dns_filter';
export const CAMPUS = 'campus';
export const SUPPORT = 'support';
export const FEATURE_TOGGLE = 'feature_toggle';

export function enableFeatureToggle(token: string): string {
  return isDevMode() ? token : FEATURE_TOGGLE;
}
