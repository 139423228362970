import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AVALAIBLE_LANGUAGES_DEFINITIONS } from '@core/constants/available-languages.constant';
import { translocoInlineLoaderFactory } from '@core/tools/translocoInlineLoader.factory';
import { provideTranslocoScope } from '@jsverse/transloco';
import { NotFound404Component } from './not-found404/not-found404.component';

const routes: Routes = [

  // Auth
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  // Layout (need authentication)
  { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), providers: [
    provideTranslocoScope({
      scope: 'layout', loader: translocoInlineLoaderFactory('../assets/i18n', AVALAIBLE_LANGUAGES_DEFINITIONS)
    })
  ] },
  { path: 'not-found', component: NotFound404Component },
  { path: '**', pathMatch: 'full', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: false })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
