<ng-container *transloco="let t; read: 'signup'">
  <div class="full-height">
    <div class="cube"></div>
    <div class="logo"></div>
    <section class="signup" [ngClass]="{'mobile': handset}">
      <header class="title-container">
        <h4 class="secondary">{{ t('subtitle') }}</h4>
        <span class="flex"></span>
        <button mat-icon-button type="button" color="primary" [routerLink]="['auth/login']" class="close">
          <mat-icon>clear</mat-icon>
        </button>
      </header>
      <article>
        <mat-stepper linear labelPosition="end" [selectedIndex]="selected" #signupStepper class="header-8 gold-header" color="accent">
          <mat-step [completed]="licenceCheck" [editable]="location.includes('activate')">
            <ng-template matStepLabel>{{ t('step.licence.title') }}</ng-template>
            <form [formGroup]="licenceForm" class="licence-form">
              <span class="hint">{{ t('step.licence.hint') }}</span>

              <mat-form-field>
                <mat-label>{{ t('step.licence.login') }}</mat-label>
                <input matInput type="text" name="login" formControlName="login" placeholder="{{ t('step.licence.login') }}" required>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ t('step.licence.password') }}</mat-label>
                <input matInput
                        [type]="hide ? 'password' : 'text'"
                        name="password"
                        formControlName="password"
                        autocomplete="new-password"
                        placeholder="{{ t('step.licence.password') }}" required>
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>

              <div class="error-container">
                <div *ngIf="submitted && licenceForm.get('login')?.errors">
                  <span *ngIf="licenceForm.get('login')?.hasError('required')">{{ t('step.licence.errors.login_required') }}</span>
                </div>
                <div *ngIf="submitted && licenceForm.get('password')?.errors">
                  <span *ngIf="licenceForm.get('password')?.hasError('required')">{{ t('step.licence.errors.password_required') }}</span>
                </div>
                <span *ngIf="!submitted && errorMessage">
                  <span>{{errorMessage}}</span>
                </span>
              </div>
              <footer class="actions">
                <button mat-button
                        color="accent"
                        type="submit"
                        name="step1btn"
                        [disabled]="licenceForm.invalid"
                        (click)="onSubmitLicence(signupStepper)">{{ t('buttons.next') }}</button>
              </footer>
            </form>
          </mat-step>

          <mat-step [completed]="adminCreated" [editable]="!adminCreated">
            <form [formGroup]="adminForm" class="admin-form" (ngSubmit)="onAdminCreation(signupStepper)">
              <ng-template matStepLabel>{{ t('step.account.title') }}</ng-template>
              <div class="row">

                <div class="column">
                  <mat-form-field>
                    <mat-label>{{ t('step.account.first_name') }}</mat-label>
                    <input type="text"
                            matInput
                            formControlName="first_name"
                            name="first_name"
                            placeholder="{{ t('step.account.first_name') }}">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>{{ t('step.account.last_name') }}</mat-label>
                    <input type="text"
                            matInput
                            formControlName="last_name"
                            name="last_name"
                            placeholder="{{ t('step.account.last_name') }}">
                  </mat-form-field>
                </div>

                <div class="column">
                  <mat-form-field>
                    <mat-label>{{ t('step.account.email') }}</mat-label>
                    <input type="email"
                            matInput
                            formControlName="email"
                            name="email"
                            placeholder="{{ t('step.account.email') }}" required>
                    <mat-error>{{ getErrorMessage('email') }}</mat-error>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>{{ t('step.account.confirm_email') }}</mat-label>
                    <input type="email"
                            matInput
                            formControlName="confirm_email"
                            name="confirm_email"
                            [errorStateMatcher]="matcher"
                            placeholder="{{ t('step.account.confirm_email') }}" required>
                    <mat-error>{{ getErrorMessage('confirm_email') }}</mat-error>
                  </mat-form-field>

                  <mat-error *ngFor="let msg of apiErrors">{{msg}}</mat-error>
                </div>
              </div>

              <footer class="actions">
                <button mat-button color="primary" type="button" [routerLink]="['../login']">{{ t('buttons.cancel') }}</button>
                <button mat-button class="withSpinner" color="accent" type="submit" [disabled]="!adminForm.valid">
                  <span *ngIf="!loading">{{ t('buttons.save') }}</span>
                  <mat-spinner *ngIf="loading" diameter="25"></mat-spinner>
                </button>
              </footer>
            </form>
          </mat-step>

          <mat-step [completed]="false" [editable]="adminCreated">
            <ng-template matStepLabel>{{ t('step.finalize.title') }}</ng-template>
            <article class="final-step">
              <header><h3>{{ t('step.finalize.subtitle') }}</h3></header>
              <div class="tile-content">
                <p>{{ t('step.finalize.paragraph_1') }}</p>
                <p>
                  <span>{{ t('step.finalize.paragraph_2') }}</span><br>
                  <span>{{ t('step.finalize.paragraph_3') }}</span>
                </p>
              </div>
              <footer class="resend">
                <button mat-button color="primary" (click)="resend()" type="button">{{ t('buttons.resend') }}</button>
              </footer>
            </article>
          </mat-step>

        </mat-stepper>
      </article>
    </section>
  </div>
</ng-container>
