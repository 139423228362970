import { HttpClient } from '@angular/common/http';
import {
  Translation,
  TranslocoLoader,
  provideTransloco,
  TranslocoModule,
} from '@jsverse/transloco';
import { Injectable, Injector, NgModule } from '@angular/core';
import { TRANSLOCO_APP_CONFIG } from './transloco-app.config';
import { Observable } from 'rxjs/internal/Observable';


@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  get http(): HttpClient {
    return this.injector.get(HttpClient);
  }
  constructor(private injector: Injector) {}

  getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    // Disabling this rule because of an issue with 'provideTransloco' type signature. Check ini future Transloco version if the issue persist.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    provideTransloco({
        config: TRANSLOCO_APP_CONFIG,
        loader: TranslocoHttpLoader
    })
],
})
export class TranslocoRootModule {}
