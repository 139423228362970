import { HttpClient } from "@angular/common/http";
import { Inject } from "@angular/core";
import { Router } from "@angular/router";
import { EnvironmentService } from "@core/domains/environment/environment.service";
import { WINDOW } from "@core/tools/window";
import { Observable } from "rxjs/internal/Observable";
import { Subject } from "rxjs/internal/Subject";
import { ObtainTokensIO, AccessTokenIO, TokenIO } from "./login.interfaces";

export abstract class LoginService {
  private _client_id: string;
  baseUrl: string;
  storage: Storage;

  private _loggedIn = new Subject<void>();
  public loggedIn: Observable<void> = this._loggedIn.asObservable();

  get clientId(): string {
    return this._client_id;
  }

  constructor(
    protected envService: EnvironmentService,
    @Inject(WINDOW) window: Window,
    protected http: HttpClient,
    protected router: Router
  ) {
    const domain = this.envService.environment.api_domain;
    this.baseUrl = `${domain}/api/auth`;
    this.storage = window.localStorage;
  }

  abstract onLogin(email: string, password: string): Observable<ObtainTokensIO>
  abstract onLogout(): void
  abstract refreshToken(): Observable<AccessTokenIO>
  abstract verifyToken(): Observable<string | null>

  isLoggedIn(): boolean {
    return !!this.getAuthToken();
  }

  isRefreshTokenValid(): boolean {
    const refreshToken = this.storage.getItem('Refresh');

    if (refreshToken) {
      const payload: TokenIO = this.parseToken(refreshToken);
      const now = Date.now();
      const expiry = payload.exp * 1000;

      return now < expiry;
    } else {
      return false;
    }
  }

  getAuthToken(): string | null {
    return this.storage.getItem('AuthToken');
  }

  setExpiry(token: string): void {
    const payload = this.parseToken(token);
    this.storage.setItem('Expiry', payload.exp.toString());
  }

  checkExpiry(threshold = 300): boolean {
    const expiry: number = Number(this.storage.getItem('Expiry')) - threshold;
    const now = Date.now() / 1000;
    return now >= expiry;
  }

  parseToken(token: string): TokenIO {
    const jwt = JSON.parse(window.atob(token.split('.')[1])) as TokenIO;
    this._client_id = jwt.client_id;
    return jwt;
  }

  setTokens(response: ObtainTokensIO): void {
    this.storage.setItem('Refresh', response.refresh);
    this.storage.setItem('AuthToken', response.access);
    this.setExpiry(response.access);
    this._loggedIn.next();
  }

  deleteTokens(): void {
    this.storage.removeItem('AuthToken');
    this.storage.removeItem('Expiry');
    this.storage.removeItem('Refresh');
  }
}
