<ng-container *transloco="let t; read: 'layout.navigation.notfound'">
  <div class="container-404">
    <header>
      <h1>{{ t('title') }}</h1>
      <p class="back">
        <button mat-stroked-button color="primary" type="button" (click)="back()"><mat-icon>arrow_back</mat-icon></button>
        <button mat-flat-button color="primary" type="button" [routerLink]="['/']">{{ t('back') }}</button>
      </p>
    </header>
    <div class="image-container"></div>
  </div>
</ng-container>
