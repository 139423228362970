import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CrossFieldErrorStateMatcher, MustMatch } from '@core/tools/custom.validator';
import { ErrorManagementService, extractErrorCode, ERROR_CODES } from '@core/tools/error.codes';
import { TranslocoService, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, delay, finalize, tap } from 'rxjs/operators';
import { ActivationPayload, ActivationService } from './activation.service';

@Component({
  selector: 'olfeo-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['../auth.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: {scope: 'auth/reset', alias: 'reset'} }]
})
export class ActivationComponent implements OnInit {
  private _url: URL;
  isActivated = false;
  loading = false;
  apiErrors: string[];
  passwordForm: UntypedFormGroup;
  matcher: CrossFieldErrorStateMatcher = new CrossFieldErrorStateMatcher();

  constructor(
    private builder: UntypedFormBuilder,
    private activate: ActivationService,
    private router: Router,
    private loco: TranslocoService,
    private errors: ErrorManagementService
  ) {
    this._url = new URL(window.location.href);
  }

  ngOnInit(): void {
    this.passwordForm = this.initForm();
  }

  initForm(): UntypedFormGroup {
    return this.builder.group({
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(12)]),
      confirm: new UntypedFormControl('', [Validators.required])
    }, {validators: MustMatch('password', 'confirm')});
  }

  onSubmit(): void {
    const [uid, token] = this._getParams();
    const password = this.passwordForm.controls['password'].value as string;
    const payload: ActivationPayload = {uid, token, password};
    this._activate(payload).subscribe();
  }

  getErrorMessage(ctrl: string): string | null {
    return this.errors.abstractControlErrorManager(this.passwordForm, ctrl);
  }

  private _getParams(): string[] {
    return [this._url.searchParams.get('uid') as string, this._url.searchParams.get('token') as string];
  }

  private _activate(payload: ActivationPayload): Observable<unknown> {
    this.loading = true;
    return this.activate.activation(payload).pipe(
      tap(() => this.isActivated = true),
      delay(3000),
      tap(() => this.router.navigate(['/']).catch((e: unknown) => console.error(e))),
      catchError((e: HttpErrorResponse) => this._displayErrors(e)),
      finalize(() => {
        this.loading = false;
        this.isActivated = true;
      })
    );
  }

  private _displayErrors(e: HttpErrorResponse): Observable<string[]> {
    const error = extractErrorCode(e); // if extract codes, use new API errors format else use legacy format
    const allErrors = Object.entries(e.error as {[key: string]: string[]});
    const messages: string[] = allErrors.map((item: [string, string[]]) => {
      return error && ERROR_CODES.includes(error.code) ? this.loco.translate(`API_ERRORS.${item[0]}.${item[1][0]}`) : item[1][0];
    });
    this.apiErrors = messages;
    return of(messages);
  }
}
