import { InjectionToken } from '@angular/core';
import { AdminMe, BaseAdminMe } from '../adminMe/admin-me';

export const ADMIN_CONFIG = new InjectionToken<AdminToken>('config');

export interface ProvideAppConf {
  provide: InjectionToken<AdminToken>,
  useValue: AdminToken
}

export interface AdminToken  {
  api: string,
  licence: string
}


export interface AdministratorIO extends BaseAdminMe {
  is_active: boolean;
  is_enabled: boolean;
}

export class Administrator extends AdminMe {
  override is_active: boolean;
  is_enabled: boolean;

  constructor(json: AdministratorIO) {
    super(json);
    this.id = json.id;
    this.is_active = json.is_active;
    this.is_enabled = json.is_enabled;
  }
}
