import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignUpService, AdminPayload } from './signup.service';
import { AdminMeFormModel } from '@core/domains/adminMe/admin-me.form.model';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { CrossFieldErrorStateMatcher, Matching } from '@core/tools/custom.validator';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorManagementService, ERROR_CODES, extractErrorCode } from '@core/tools/error.codes';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoginService } from '@core/auth/login.service';

@Component({
  selector: 'olfeo-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['../auth.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: {scope: 'auth/signup', alias: 'signup'} }]
})
export class SignUpComponent implements OnInit {
  matcher = new CrossFieldErrorStateMatcher();
  location: string = window.location.href;
  adminModel: AdminMeFormModel;
  selected = 0;
  errorMessage: string;
  handset: boolean;
  licenceCheck = false;
  submitted = false;
  loading = false;
  adminCreated = false;
  activated: boolean;
  licenceForm: UntypedFormGroup;
  adminForm: UntypedFormGroup;
  apiErrors: string[];
  hide = true;
  hideCreationPassword = true;
  hideCreationPasswordConfirm = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private srv: SignUpService,
    private login: LoginService,
    private router: Router,
    public errors: ErrorManagementService,
    public snackbar: MatSnackBar,
    public loco: TranslocoService
  ) {
    this.adminModel = new AdminMeFormModel();
  }

  ngOnInit(): void {
    if (this.login.isLoggedIn()) {
      this.router.navigate(['']);
    }
    this.createForm();
  }

  createForm(): void {
    this.licenceForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.adminForm = this.formBuilder.group(this.adminModel.getNewAdminControls());
    this.adminForm.setValidators(Matching('email', 'confirm_email'));
  }

  getErrorMessage(ctrl: string): string | null {
    return this.errors.abstractControlErrorManager(this.adminForm, ctrl, {params: {value: 'email'}})
  }

  onSubmitLicence(stepper: MatStepper): void {
    this.submitted = true;
    if (this.licenceForm.invalid) {
        return;
    }

    const login = this.licenceForm.controls['login'].value as string;
    const password = this.licenceForm.controls['password'].value as string;

    this.srv.onLicenceLogin(login, password).pipe(
      tap(() => this.licenceCheck = true),
      tap(() => setTimeout(() => stepper.next())),
      catchError(() => {
        this.setErrors();
        return of();
      })
    ).subscribe();
  }

  onAdminCreation(stepper: MatStepper): void {
    this.loading = true;
    this.apiErrors = [];
    const licenceLogin = this.licenceForm.controls['login'].value as string;
    const licencePswd = this.licenceForm.controls['password'].value as string;
    const payload: AdminPayload = {
      email: (this.adminForm.get('email') as UntypedFormControl).value as string,
      first_name: (this.adminForm.get('first_name') as UntypedFormControl).value as string,
      last_name: (this.adminForm.get('last_name') as UntypedFormControl).value as string
    };

    this.srv.onAdminCreation(payload, licenceLogin, licencePswd).pipe(
      tap(() => this.adminCreated = true),
      tap(() => setTimeout(() => stepper.next())),
      catchError((e: HttpErrorResponse) => {
        this.apiErrors = this.setAdminCreationErrors(e);
        return of();
      }),
      finalize(() => this.loading = false)
    ).subscribe();
  }

  resend(): void {
    const licenceLogin = this.licenceForm.controls['login'].value as string;
    const licencePswd = this.licenceForm.controls['password'].value as string;
    const email = (this.adminForm.get('email') as UntypedFormControl).value as string;

    this.srv.resendActivation(email, licenceLogin, licencePswd).pipe(
      tap(() => this.snackbar.open(this.loco.translate('API_SUCCESS.email_activation'), undefined, {panelClass: 'mat-snack-bar-success', duration: 3000}))
    ).subscribe();
  }

  setErrors(): void {
    this.submitted = false;
    this.licenceCheck = false;
    this.errorMessage = this.loco.translate('API_ERRORS.legacy.login');
  }

  setAdminCreationErrors(e: HttpErrorResponse): string[] {
    const error = extractErrorCode(e);
    if (error && ERROR_CODES.includes(error.code)) {
      return [this.loco.translate(`API_ERRORS.${error.property}.${error.code}`)];
    }
    return Object.values(e.error as {[key: string]: string[]})[0];
  }
}
