import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResetPasswordNewService } from './reset-password-new.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CrossFieldErrorStateMatcher, MustMatch } from '@core/tools/custom.validator';
import { ErrorManagementService } from '@core/tools/error.codes';

@Component({
  selector: 'olfeo-forgot-new-email',
  templateUrl: './reset-password-new.component.html',
  styleUrls: ['../../auth.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: {scope: 'auth/reset', alias: 'reset'} }]
})
export class ResetPasswordNewComponent implements OnInit {
  matcher = new CrossFieldErrorStateMatcher();
  handset: boolean;
  errorMessage: string;
  submitted = false;
  registerForm: UntypedFormGroup;
  uid: string;
  token: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private srv: ResetPasswordNewService,
    private router: Router,
    private route: ActivatedRoute,
    private errors: ErrorManagementService,
    public breakpoint: BreakpointObserver,
    public loco: TranslocoService
  ) {
    this.route.queryParams.pipe(
      tap((params: Params) => {
        this.uid = params['uid'] as string;
        this.token = params['token'] as string;
      })
    ).subscribe();

    this.breakpoint.observe(['(max-width: 812px)']).pipe(
      tap((state: BreakpointState) => this.handset = state.matches)
    ).subscribe();
  }

  ngOnInit(): void {
    this.registerForm = this.createForm();
  }

  createForm(): UntypedFormGroup {
    const matchValidator: ValidatorFn = MustMatch('password', 'confirmPassword');
    return this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(12)]],
      confirmPassword: ['', Validators.required]
    }, {
      validators: matchValidator
    });
  }

  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    const password = this.registerForm.controls['password'].value as string;
    this.srv.resetPasswordConfirm(password, this.uid, this.token).pipe(
      tap(() => this.router.navigate(['/'])),
      catchError(() => of(this.setErrors()))
    ).subscribe();
  }

  setErrors(): void {
    this.submitted = false;
    this.errorMessage = this.loco.translate('API_ERRORS.legacy.password');
  }

  getErrorMessage(ctrl: string): string | null {
    return this.errors.abstractControlErrorManager(this.registerForm, ctrl);
  }
}
