import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeriodSelectComponent } from './period-select.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MatOptionModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  override format (date: Date, displayFormat: Intl.DateTimeFormatOptions): string {
    return this._displayDates(date, displayFormat);
  }

  private _displayDates (date: Date, options: Intl.DateTimeFormatOptions): string {
    const lang = window.navigator.language;
    const formatter = new Intl.DateTimeFormat(lang, options);
    return formatter.format(date);
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TEDateFormat = {
  parse: {
    dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { month: 'numeric', year: 'numeric', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'numeric' }
  }
};

@NgModule({
  declarations: [PeriodSelectComponent],
  exports: [PeriodSelectComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
    { provide: MAT_DATE_FORMATS, useValue: TEDateFormat }
  ]
})
export class PeriodSelectModule { }
