import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AdminMe } from './admin-me';
import { filter, tap } from 'rxjs/operators';
import { AdminMeService } from '@core/domains/adminMe/adminMe.service';

@Injectable()
export class AdminMeStoreService {
  private _adminMe: BehaviorSubject<AdminMe | null> = new BehaviorSubject<AdminMe | null>(null);
  public adminMe: Observable<AdminMe> = this._adminMe.pipe(filter((admin) => !!admin)) as Observable<AdminMe>;

  public hasAdmin = false;

  constructor(private adminService: AdminMeService) { }

  initAdmin(): Observable<AdminMe> {
    return this.adminService.read().pipe(
      tap((me) => this._adminMe.next(me)),
      tap(() => this.hasAdmin = true)
    );
  }

  setAdminMe(user: AdminMe): void {
    this._adminMe.next(user);
    this.hasAdmin = !!user;
  }

  clearAdmin(): void {
    this._adminMe.next(null);
    this.hasAdmin = false;
  }
}
