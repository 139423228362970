import { Component } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { Location } from '@angular/common';

@Component({
  selector: 'app-not-found404',
  templateUrl: './not-found404.component.html',
  styleUrls: ['./not-found404.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'layout' } }]
})
export class NotFound404Component {

  constructor(private location: Location) { }

  back(): void {
    this.location.back();
  }
}
