export const WHITELIST = {
  api: [
    'auth/token/obtain/',
    'auth/admins/v2/check_license/',
    'auth/admins/v2/reset_password/',
    'auth/admins/v2/reset_password_confirm/',
    'auth/admins/v2/resend_activation/',
    'auth/admins/v2/activation/',
    'auth/admins/v2/',
    'auth/admins/awareness/reset_password/',
    'auth/admins/awareness/reset_password_confirm/',
    'auth/admins/awareness/resend_activation/',
    'auth/admins/awareness/activation/',
    'auth/admins/awareness/',
    '.json'
  ],
  location: ['/login', '/signup', '/auth']
};
