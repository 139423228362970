<ng-container *transloco="let t; read: 'reset.NEW'">
  <div class="full-height">
    <div class="cube"></div>
    <div class="cube2"></div>
    <div class="logo"></div>
    <section class="reset-password">
      <header class="title-container">
        <h4 class="secondary">{{ t('title') }}</h4>
      </header>
      <article class="form-container">
        <p>{{ t('hints.first') }}</p>
        <p>{{ t('hints.second') }}</p>
        <form [formGroup]="registerForm" (submit)="onSubmit()">
          <mat-form-field>
            <mat-label>{{ t('new') }}</mat-label>
            <input matInput type="password" name="password" formControlName="password"
                  placeholder="{{ t('new') }}"
                  required>
            <mat-error>{{ getErrorMessage('password') }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ t('confirm') }}</mat-label>
            <input matInput type="password" name="confirmPassword" formControlName="confirmPassword"
                  placeholder="{{ t('confirm') }}"
                  [errorStateMatcher]="matcher"
                  required>
            <mat-error>{{ getErrorMessage('confirmPassword') }}</mat-error>
          </mat-form-field>
          <footer>
            <a [routerLink]="['/auth/login']">< {{t('back')}}</a>
            <button mat-flat-button color="accent" type="submit" [disabled]="registerForm.invalid">{{ t('login') }}</button>
          </footer>
        </form>
      </article>
    </section>
  </div>
</ng-container>
