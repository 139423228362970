import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './signup/signup.component';
import { ResetPasswordEmailComponent } from './reset-password/email/reset-password-email.component';
import { ResetPasswordConfirmComponent } from './reset-password/confirm/reset-password-confirm.component';
import { ResetPasswordNewComponent } from './reset-password/new/reset-password-new.component';
import { ValidationComponent } from './validation/validation.component';
import { ActivationComponent } from './activation/activation.component';
import { AppRoutes } from '@core/tools/routes.interface';

export const authRoutes: AppRoutes = [
  { path: 'login', component: LoginComponent},
  { path: 'signup', component: SignUpComponent },
  { path: 'activate', component: SignUpComponent },
  { path: 'activate_v2', component: ActivationComponent },
  { path: 'reset_password', component: ResetPasswordEmailComponent },
  { path: 'reset_password/email', component: ResetPasswordEmailComponent },
  { path: 'reset_password/confirm', component: ResetPasswordConfirmComponent },
  { path: 'reset_password/new', component: ResetPasswordNewComponent },
  { path: 'email_change/confirm', component: ValidationComponent},
  { path: '', component: LoginComponent },
];
