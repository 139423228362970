import { isDevMode } from '@angular/core';
import { AVALAIBLE_LANGUAGES_DEFINITIONS } from '@core/constants/available-languages.constant';
import { getBrowserLang } from '@jsverse/transloco';

export const TRANSLOCO_APP_CONFIG = {
  availableLangs: AVALAIBLE_LANGUAGES_DEFINITIONS,
  defaultLang: getBrowserLang(),
  fallbackLang: 'en',
  preloadLangs: true,
  missingHandler: {
    allowEmpty: true
  },
  reRenderOnLangChange: false,
  prodMode: !isDevMode(),
};
