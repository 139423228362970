<ng-container *transloco="let t; read: 'reset.EMAIL'">
  <div class="full-height">
    <div class="cube"></div>
    <div class="cube2"></div>
    <div class="logo"></div>
    <section class="reset-password">
      <header class="title-container">
        <h4 class="secondary">{{ t('title') }}</h4>
      </header>
      <article>
        <form [formGroup]="registerForm" (submit)="onSubmit()" fxFlex=60>
          <mat-form-field>
            <mat-label>{{ t('email') }}</mat-label>
            <input matInput type="email" name="email" formControlName="email"
                  placeholder="{{ t('email') }}" required>
          </mat-form-field>
          <div class="error-container">
            <div *ngIf="submitted && registerForm.get('email')?.errors">
              <span *ngIf="registerForm.get('email')?.hasError('required'); then templateErrorRequired else templateError"></span>
              <ng-template #templateError><span>{{ t('errors.invalid') }}</span></ng-template>
              <ng-template #templateErrorRequired><span>{{ t('errors.required') }}</span></ng-template>
            </div>
          </div>
          <footer>
            <span></span>
            <button mat-flat-button color="accent" type="submit">{{ t('submit') }}</button>
          </footer>
        </form>
      </article>
    </section>
  </div>
</ng-container>
