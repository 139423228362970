import { FormControl, Validators } from '@angular/forms';
import { AdminMeIO } from '@core/domains/adminMe/admin-me';
import { AdminDetailsPayload, EmailPayload, PasswordPayload } from '@core/domains/adminMe/adminMe.service';

export class AdminMeFormModel {
  first_name: FormControl<string>;
  last_name: FormControl<string>;
  email: FormControl<string>;
  confirm_email: FormControl<string>;
  current_password: FormControl<string>;
  new_password: FormControl<string>;
  confirm_password: FormControl<string>;

  constructor(json?: AdminMeIO) {
    this.first_name = this.createFormControl('first_name', json);
    this.last_name = this.createFormControl('last_name', json);
    this.email = this.createFormControl('email', json);
    this.current_password = new FormControl<string>('', {nonNullable: true, validators: [Validators.required]});
    this.new_password = new FormControl<string>('', {nonNullable: true});
    this.confirm_password = new FormControl<string>('', {nonNullable: true, validators: [Validators.required]});
    this.confirm_email = new FormControl<string>('', {nonNullable: true, validators: [Validators.required, Validators.email]});

    this.new_password.setValidators([Validators.required, Validators.minLength(12)]);
    this.email.setValidators([Validators.required, Validators.email]);
  }

  createFormControl(key: keyof AdminMeIO, json?: AdminMeIO): FormControl<string> {
    return json && json[key] ? new FormControl<string>(json[key].toString(), {nonNullable: true}) : new FormControl<string>('', {nonNullable: true});
  }

  getNewAdminControls(): {[key: string]: FormControl} {
    return {
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
      confirm_email: this.confirm_email
    };
  }

  getDetailsControls(): {[key: string]: FormControl} {
    return {
      first_name: this.first_name,
      last_name: this.last_name
    };
  }

  getEmailcontrols(): {[key: string]: FormControl} {
    return {
      email: this.email,
      password: this.current_password
    };
  }

  getPasswordControls(): {[key: string]: FormControl} {
    return {
      current_password: this.current_password,
      new_password: this.new_password,
      confirm_password: this.confirm_password
    };
  }

  detailsToJson(): AdminDetailsPayload {
    return {
      first_name: this.first_name.value ,
      last_name: this.last_name.value 
    };
  }

  emailToJson(): EmailPayload {
    return {
      new_email: this.email.value ,
      current_password: this.current_password.value 
    };
  }

  passwordToJson(): PasswordPayload {
    return {
      new_password: this.new_password.value ,
      current_password: this.current_password.value 
    };
  }
}
