import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { WHITELIST } from './interceptors.whitelist';
import { Observable } from 'rxjs/internal/Observable';
import { WINDOW } from '@core/tools/window';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private _storage: Storage;
  constructor(
    @Inject(WINDOW) window: Window
  ) {
    this._storage = window.localStorage;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const except = WHITELIST.api;
    if ( except.some((item: string) => req.url.endsWith(item))) {
      return next.handle(req);
    }
    const serviceToken = this._getAuthToken();
    if (serviceToken) {
      return next.handle(req.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Authorization': `Bearer ${serviceToken}`
        }
      }));
    }
    return next.handle(req);
  }

  private _getAuthToken(): string | null {
    return this._storage.getItem('AuthToken');
  }
}
