
export interface IntlDateSegment {
  type: string;
  value: string | number;
}

export interface DateFormatterOptions {
  withTime?: boolean;
  language?: string;
  format?: string;
  separator?: string;
}

export interface SegmentExtract {
  date: {[name: string]: string[]};
  time: string[];
}

const lang = window.navigator.language;
const DEFAULT_FORMATTER_OPTIONS: DateFormatterOptions = {
  withTime: false,
  language: lang,
  format: lang.toLowerCase().includes('fr') ? 'DD-MM-YYYY' : 'YYYY-MM-DD',
  separator: lang.toLowerCase().includes('fr') ? '/' : '-'
};


/**
 * DateFormatter helper class.
 * Format & handle dates using Intl.DateTimeFormat API, wich has better performances as Date API
 */
export class DateFormatter {
  private static readonly _intl_options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false};
  private static readonly _segments_to_extract: SegmentExtract = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    date: { 'YYYY-MM-DD': ['year', 'month', 'day'], 'DD-MM-YYYY': ['day', 'month', 'year'] },
    time: ['hour', 'minute']
  };

  private static _convert2digit(n: number, type?: string): string {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return type && type === 'year' ? n.toString() : ('00' + n).slice(-2);
  }

  private static _extract(segments: IntlDateSegment[], segment: string): IntlDateSegment | undefined {
    return segments.find((item: IntlDateSegment) => item.type === segment);
  }

  private static _timeFormatter(segments: IntlDateSegment[]): string {
    return this._segments_to_extract.time.reduce((acc: string, current: string, index: number) => {
      const segment = this._extract(segments, current) as IntlDateSegment;
      return index === 0 ? acc.concat(`${this._convert2digit(Number(segment.value))}`) : acc.concat(`:${this._convert2digit(Number(segment.value))}`);
    }, '');
  }

  private static _dateFormatter(segments: IntlDateSegment[], format: string, separator: string): string {
    return this._segments_to_extract.date[format].reduce((acc: string, current: string, index: number) => {
      const segment = this._extract(segments, current) as IntlDateSegment;
      return index === 0  ? acc.concat(`${this._convert2digit(Number(segment.value), segment.type)}`)
                          : acc.concat(`${separator}${this._convert2digit(Number(segment.value), segment.type)}`);
    }, '');
  }

  static format(date: Date, options?: DateFormatterOptions): string {
    options = options ? Object.assign({}, DEFAULT_FORMATTER_OPTIONS, options) : DEFAULT_FORMATTER_OPTIONS;
    if (date) {
      const newDate = new Date(date);
      const formatter = new Intl.DateTimeFormat(options.language, this._intl_options);
      const segments = formatter.formatToParts(newDate);

      const formatedDate = options.format && options.separator ? this._dateFormatter(segments, options.format, options.separator) : '';
      const formatedTime = options.withTime ? this._timeFormatter(segments) : undefined;

      return formatedTime ? `${formatedDate} ${formatedTime}` : formatedDate;
    } else {
      return '-';
    }
  }

  static getTime(date: Date): string {
    const newDate = new Date(date);
    const formatter = new Intl.DateTimeFormat(DEFAULT_FORMATTER_OPTIONS.language, this._intl_options);
    return this._timeFormatter(formatter.formatToParts(newDate));
  }

  static addMounths(date: Date, mounthstoAdd: number): Date {
    const newDate = new Date(date);
    const d = date.getDate();
    newDate.setMonth(newDate.getMonth() + mounthstoAdd);
    if (newDate.getDate() !== d) {
      newDate.setDate(0);
    }
    return newDate;
  }

  static addDays(date: Date, daysToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return newDate;
  }

  static addHours(date: Date, hoursToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setTime(date.getTime() + (hoursToAdd * 60 * 60 * 1000));
    return newDate;
  }

  static isDateGreaterThan(date1: Date, date2: Date): boolean {
    return date1 > date2;
  }

  static isDateLowerThan(date1: Date, date2: Date): boolean {
    return date1 < date2;
  }

  /**
   * Returns the number of days between now and a later date.
   * @param until the later date
   * @returns number representing the number of days between now and end date.
   */
  static getDaysLeft(until: Date): number {
    const day_duration: number = 1000 * 60 * 60 * 24;
    const end_prim: number = until.valueOf();
    const now: number = new Date().setHours(0, 0, 0);
    const interval: number = end_prim - now;

    return Math.abs(Math.round(interval / day_duration));
  }

  static getDateTimeFormatter(): Intl.DateTimeFormat {
    const locale = window.navigator.language;
    const formatter = new Intl.DateTimeFormat(locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    return formatter;
  }

  static getDaysBetweenDate(start_date: Date, end_date: Date): number {
    const day_duration: number = 1000 * 60 * 60 * 24;
    const end_prim: number = end_date.valueOf();
    const nbr_start_date: number = start_date.setHours(0, 0, 0);
    const interval: number = end_prim - nbr_start_date;

    return Math.abs(Math.round(interval / day_duration));
  }
}
