import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ResetPasswordEmailService } from './reset-password-email.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'olfeo-forgot-password-email',
  templateUrl: './reset-password-email.component.html',
  styleUrls: ['../../auth.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: {scope: 'auth/reset', alias: 'reset'} }]
})
export class ResetPasswordEmailComponent implements OnInit {
  errorMessage: string;
  handset: boolean;
  submitted = false;
  registerForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private srv: ResetPasswordEmailService,
    private router: Router,
    public breakpoint: BreakpointObserver
  ) {
    this.breakpoint.observe(['(max-width: 812px)']).pipe(
      tap((state: BreakpointState) => this.handset = state.matches)
    ).subscribe();
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    const email = this.registerForm.controls['email'].value as string;
    this.srv.sendPasswordResetEmail(email).pipe(
      tap(() => this.router.navigate(['/auth/reset_password/confirm'])),
      catchError(() => of(this.setErrors()))
    ).subscribe();
  }

  setErrors(): void {
    this.submitted = false;
  }
}
