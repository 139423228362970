import { isDevMode } from '@angular/core';
import { EnvironmentIO } from './environment.interfaces';


export class Environment {
  production: boolean;
  api_domain: string;
  matomo: {
    site_id: number;
    tracker_url: string;
  };
  kibana?: {
    host: string;
  } | undefined;
  apiLocale: {
    language: string;
    format: string;
  };
  version: {
    app: string;
  };

  constructor(json: EnvironmentIO) {
    this._init(json);
  }

  private _init(json: EnvironmentIO): void {
    this.production = !isDevMode();
    this.api_domain = json.api_domain;
    this.matomo = {
      site_id: json.matomo.site_id,
      tracker_url: json.matomo.tracker_url
    };
    this.kibana = json.kibana ? {host: json.kibana.host} : undefined;
    this.apiLocale = json.apiLocale;
    this.version = {app: json.version.app};
  }
}
