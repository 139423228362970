import { DOCUMENT } from "@angular/common";
import { InjectionToken, inject } from "@angular/core";

export const WINDOW = new InjectionToken<Window>('Window', {
  factory: () => {
    const {defaultView} = inject(DOCUMENT);
    if (!defaultView) {
      throw new Error('Window not available');
    }
    return defaultView;
  }
});
