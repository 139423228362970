import { CAMPUS, SAAS_DNS_FILTER, SAAS_URL_FILTER } from '@core/constants/products.constant';
import { Resource } from '@core/resources/resource';

export interface AdminMeIO {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  products: string[];
  features: string[];
  last_login: string;
  role: RoleAdmin
  // app: string;
}

export type RoleAdmin = 'admin' | 'adminless' | "adminreadonly";

export interface BaseAdminMe extends AdminMeIO {
  id: string;
  email: string;
  password?: string;
  first_name: string;
  last_name: string;
  features: string[];
  products: string[];
  last_login: string;
  role: RoleAdmin;
  // app: string;
}

/**
 * Class representing the administrator currently logged in
 * @extends Resource
 * @implements {BaseAdminMe}
 */
export class AdminMe extends Resource implements BaseAdminMe {
  /**
   * The default home page path, relative to the client's licences
   * @type {string}
   * @private
   */
  private _defaultModulePath: string;

  override id: string;
  email: string;
  is_active: boolean;
  password: string | undefined;
  first_name: string;
  last_name: string;

  /**
   * Used for feature toggling
   * @type {string[]}
   * @public
   */
  features: string[];

  /**
   * Products admin has access to. Licences
   * @type {string[]}
   * @public
   */
  products: string[];


  /**
   * @type {string}
   */
  last_login: string;

  /**
   * @type {RoleAdmin}
   */
  role: RoleAdmin;


  /**
   * @constructor
   * @param {BaseAdminMe} json
   */
  constructor (json: BaseAdminMe) {
    super();
    this.id = json?.id ?? '';
    this.email = json?.email ?? '';
    this.password = json?.password ?? '';
    this.first_name = json?.first_name ?? '';
    this.last_name = json?.last_name ?? '';
    this.features = json?.features ?? [];
    this.products = json?.products ?? [];
    this.last_login = json?.last_login ?? '';
    this.role = json?.role ?? 'adminreadonly';
  }

  /**
   * Set the default home page, relatives to the client licences
   * @param {string[]} products admin.products, the products admin has access
   */
  set defaultModulePath(route: string) {
    this._defaultModulePath = route;
  }

  /**
   * getter that returns the admin's home page
   * @getter
   * @returns {string}
   * @public
   */
  get defaultModulePath(): string {
    return this._defaultModulePath;
  }

  /**
   * Check if logged admin has saas_url_filter license.
   * @getter
   * @returns {boolean}
   */
  get hasURLfiltering(): boolean {
    return this.products.includes(SAAS_URL_FILTER);
  }

  /**
   * Check if logged admin has saas_dns_filter license.
   * @getter
   * @returns {boolean}
   */
  get hasDNSfiltering(): boolean {
    return this.products.includes(SAAS_DNS_FILTER);
  }

  /**
   * Check if logged admin has a campus license.
   * @getter
   * @returns {boolean}
   */
  get hasAwareness(): boolean {
    return this.products.includes(CAMPUS);
  }

  /**
   * Return the admin's full name, or his email address
   * @returns {string}
   * @public
   */
  getFullName(): string {
    return this.first_name && this.last_name ? `${this.first_name} ${this.last_name}` : this.email;
  }
}
