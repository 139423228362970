import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentBottomSheetComponent } from './consent-bottom-sheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [ConsentBottomSheetComponent],
  exports: [ConsentBottomSheetComponent],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    MatButtonModule,
    TranslocoModule
  ]
})
export class ConsentBottomSheetModule { }
