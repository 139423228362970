import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

export const ERROR_CODES = ['invalid', 'required', 'blank', 'unique', 'does_not_exist'];

export interface ErrorCode {
  property: string;
  code: string;
}

export interface AbstractControlErrorManagerOptions {
  /**
   * The scope of translation keys.
   * @default API_ERRORS
   * @example API_ERRORS.name.unique
   */
  scope?: string;

  /**
   * The parameter object to pass along the translation
   * @example this.loco.translate('API_ERRORS.maxlength', {value: '80/60'}). The param object is {value: '80/60'}.
   */
  params?: {[key: string]: string}
}

export const extractErrorCode = (e: HttpErrorResponse): ErrorCode | undefined => {
  const error = e.error as {[key: string]: string[]};
  try {
    const raw: unknown[] = Object.values(error);
    const value = raw[0] as string[];
    return {
      property: Object.keys(error)[0],
      code: value[0]
    };
  } catch (_) {
    return undefined;
  }
};

@Injectable()
export class ErrorManagementService {

  constructor(
    private snack: MatSnackBar,
    private loco: TranslocoService
  ) {}


  errorManagement<T>(e: HttpErrorResponse, newObservable?: T): Observable<T> {
    const error = this._extractErrorCode(e);
    if (e.status === 500) {
      this.snack.open(this.loco.translate('API_ERRORS.common'), undefined, {panelClass: 'mat-snack-bar-error'});
    } else {
      this.snack.open(this.loco.translate(error ? `API_ERRORS.${error.property}.${error.code}` : 'API_ERRORS.common'), undefined, {panelClass: 'mat-snack-bar-error'});
    }
    return newObservable ? of<T>(newObservable) : of();
  }

  /**
   * display success snack bar message
   * @param msg string: Transloco translation string
   */
  successManagement(msg: string): void {
    this.snack.open(this.loco.translate(msg), undefined, {panelClass: 'mat-snack-bar-success'});
  }

  setControlError(form: FormGroup, e: HttpErrorResponse): void {
    const error = this._extractErrorCode(e);
    if (error && form.get(error.property)) {
      const ctrl = form.get(error.property);
      if (ctrl) {
        ctrl.setErrors({[error.code]: true});
        ctrl.markAsDirty();
      }
    }
  }

  /**
   * ErrorManagementService.abstractControlErrorManager
   * Method used to display error messages under formControls.
   * Works in conjonction with i18n root files, where the name of the form control is a property of the i18n json file, that contains an object,
   * where the form control error is the key containing the translation
   * @example with a formControlName 'description', the i18n root file must contain all possible errors linked to that control:
   * {
   *   "description": {
   *     "required": "this field is required",
   *     "maxlength": "..."
   *     etc...
   *   }
   * }
   * @param form the formGroup instance where abstractControlErrorManager applied
   * @param controlName string: the name of the control where the error will be displayed
   * @param options AbstractControlErrorManagerOptions: An options object, where you can pass a reference to the collection of translation keys
   * @returns a translated error string, or null if there's no error in the abstractControl
   */
  abstractControlErrorManager(form: FormGroup, controlName: string, options: AbstractControlErrorManagerOptions = {}): string | null {
    const ctrl = form.get(controlName);
    const errors: ValidationErrors | null = ctrl?.errors || null;
    if (errors) {
      const errorCode: string = Object.keys(errors)[0];
      return this.loco.translate(`${options?.scope ?? 'API_ERRORS'}.${controlName}.${errorCode}`, options.params);
    }
    return null;
  }

  private _extractErrorCode(e: HttpErrorResponse): ErrorCode | undefined {
    const error = e.error as {[key: string]: string[]};
    try {
      const raw: Array<string[]> = Object.values(error);
      const value: string[] = raw[0];
      return {
        property: Object.keys(error)[0],
        code: value[0]
      };
    } catch (_e) {
      return undefined;
    }
  }
}
