import { InlineLoader, LangDefinition, Translation } from '@jsverse/transloco';

const defaultAvailableLanguages = [{id: 'fr', label: 'fr'}, {id: 'en', label: 'en'}];

export function translocoInlineLoaderFactory( path: string, availableLangs: LangDefinition[] = defaultAvailableLanguages) : InlineLoader {
  return availableLangs.reduce((acc, lang) => {
    acc[lang.id] = () => import(`../../../${path}/${lang.id}.json`) as Promise<Translation>;
    return acc;
  }, {} as InlineLoader);
}
